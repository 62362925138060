import { ReactNode, useContext } from 'react'

import { StringsContext } from '@lib/strings'
import { CartContext } from '@lib/cart/context'

import Button from '@components/buttons/button'

interface ProductAddProps {
  onClick: () => void
  primary?: boolean
  secondary?: boolean
  white?: boolean
  className?: string
  children: ReactNode
}

const ProductAdd = ({
  onClick,
  primary,
  secondary,
  white,
  className,
  children,
}: ProductAddProps) => {
  const strings = useContext(StringsContext)
  const { cart, isCartProductAdding } = useContext(CartContext)

  return (
    <Button
      id="product-add-to-cart-button"
      className={className}
      large
      primary={primary}
      secondary={secondary}
      white={white}
      disabled={!cart || isCartProductAdding}
      onClick={onClick}
    >
      {!cart && strings.buttonUnavailable}
      {isCartProductAdding && strings.buttonAdding}
      {cart && !isCartProductAdding && children}
    </Button>
  )
}

export default ProductAdd
