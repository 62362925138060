// The following code is exported from @reach/rect@v0.18.0 and refactored

import { RefObject, useRef, useState } from 'react'

import { useIsomorphicLayoutEffect } from './hooks'

interface RectState {
  rect: DOMRect | undefined
  hasRectChanged: boolean
  callbacks: Function[]
}

interface PartialRect extends Partial<DOMRect> {
  readonly bottom: number
  readonly height: number
  readonly left: number
  readonly right: number
  readonly top: number
  readonly width: number
}

/**
 * @see Docs https://reach.tech/rect#userect
 */
interface UseRectOptions {
  /**
   * Tells `Rect` to observe the position of the node or not. While observing,
   * the `children` render prop may call back very quickly (especially while
   * scrolling) so it can be important for performance to avoid observing when
   * you don't need to.
   *
   * This is typically used for elements that pop over other elements (like a
   * dropdown menu), so you don't need to observe all the time, only when the
   * popup is active.
   *
   * Pass `true` to observe, `false` to ignore.
   *
   * @see Docs https://reach.tech/rect#userect-observe
   */
  observe?: boolean
  /**
   * Calls back whenever the `rect` of the element changes.
   *
   * @see Docs https://reach.tech/rect#userect-onchange
   */
  onChange?: (rect: PartialRect) => void
}

let useRectObservedNodes = new Map<Element, RectState>()
let useRectRafId: number
const useRectCompareProps: (keyof DOMRect)[] = [
  'bottom',
  'height',
  'left',
  'right',
  'top',
  'width',
]

const observeChanges = () => {
  const changedStates: RectState[] = []

  useRectObservedNodes.forEach((state, node) => {
    const newRect = node.getBoundingClientRect()

    if (
      useRectCompareProps.some((prop) => newRect[prop] !== state.rect?.[prop])
    ) {
      state.rect = newRect
      changedStates.push(state)
    }
  })

  changedStates.forEach((state) => {
    state.callbacks.forEach((cb) => cb(state.rect))
  })

  useRectRafId = window.requestAnimationFrame(observeChanges)
}

/**
 * Measures DOM elements (aka. bounding client rect).
 *
 * @see getBoundingClientRect https://developer.mozilla.org/en-US/docs/Web/API/Element/getBoundingClientRect
 * @see Docs                  https://reach.tech/rect
 * @see Source                https://github.com/reach/reach-ui/tree/main/packages/rect
 * @param nodeRef
 * @param observeOrOptions
 */
export function useRect<T extends Element = HTMLElement>(
  nodeRef: RefObject<T | undefined | null>,
  observeOrOptions?: UseRectOptions
): null | DOMRect {
  let observe: boolean = observeOrOptions?.observe ?? true
  let onChange: UseRectOptions['onChange'] = observeOrOptions?.onChange

  let [element, setElement] = useState(nodeRef.current)
  let initialRectIsSet = useRef(false)
  let initialRefIsSet = useRef(false)
  let [rect, setRect] = useState<DOMRect | null>(null)
  let onChangeRef = useRef(onChange)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useIsomorphicLayoutEffect(() => {
    onChangeRef.current = onChange
    if (nodeRef.current !== element) {
      setElement(nodeRef.current)
    }
  })

  useIsomorphicLayoutEffect(() => {
    if (element && !initialRectIsSet.current) {
      initialRectIsSet.current = true
      setRect(element.getBoundingClientRect())
    }
  }, [element])

  useIsomorphicLayoutEffect(() => {
    if (!observe) {
      return
    }

    let elem = element
    // State initializes before refs are placed, meaning the element state will
    // be undefined on the first render. We still want the rect on the first
    // render, so initially we'll use the nodeRef that was passed instead of
    // state for our measurements.
    if (!initialRefIsSet.current) {
      initialRefIsSet.current = true
      elem = nodeRef.current
    }

    if (!elem) {
      if (process.env.NODE_ENV !== 'production') {
        console.warn('You need to place the ref')
      }
      return
    }

    const cb = (rect: DOMRect) => {
      onChangeRef.current?.(rect)
      setRect(rect)
    }
    let wasEmpty = useRectObservedNodes.size === 0

    if (useRectObservedNodes.has(elem)) {
      useRectObservedNodes.get(elem)!.callbacks.push(cb)
    }

    if (!useRectObservedNodes.has(elem)) {
      useRectObservedNodes.set(elem, {
        rect: undefined,
        hasRectChanged: false,
        callbacks: [cb],
      })
    }

    if (wasEmpty) {
      observeChanges()
    }

    return () => {
      if (!elem) {
        return
      }

      let state = useRectObservedNodes.get(elem)

      if (state) {
        // Remove the callback
        const index = state.callbacks.indexOf(cb)

        if (index >= 0) {
          state.callbacks.splice(index, 1)
        }

        // Remove the node reference
        if (!state.callbacks.length) {
          useRectObservedNodes.delete(elem)
        }

        // Stop the loop
        if (!useRectObservedNodes.size) {
          cancelAnimationFrame(useRectRafId)
        }
      }
    }
  }, [observe, element, nodeRef])

  return rect
}
