import {
  CSSProperties,
  ReactNode,
  useCallback,
  useEffect,
  useState,
  useRef,
  useContext,
} from 'react'
import { useRouter } from 'next/router'
import { motion, Variants } from 'framer-motion'
import { LocomotiveScrollProvider } from 'react-locomotive-scroll'

import { SanitySiteFragment } from '@data/sanity/queries/types/site'
import { SanityAnyPage } from '@data/sanity/queries/types/page'
import { pageTransitionSpeed } from '@lib/animate'
import { isBrowser, isMobileSafari } from '@lib/helpers'
import { useWindowSize } from '@lib/hooks'
import { SiteContext } from '@lib/site'

import CookieBar from '@modules/shared/cookie-bar'
import Footer from '@modules/shared/footer'
import Header, { HeaderSizeValues } from '@modules/shared/header'
import VideoModal from '@components/video/video-modal'
import HeadSeo from './head-seo'

interface CSSPropertiesWithHeader extends CSSProperties {
  '--headerHeight'?: string
  '--headerBottom'?: string
}

interface LayoutProps {
  site: SanitySiteFragment
  page: SanityAnyPage
  children: ReactNode
  canonicalUrl?: string
  productSchema?: string
  blogPostSchema?: string
  hasSmoothScroll?: boolean
}

const variants: Variants = {
  initial: {
    opacity: 0,
  },
  enter: {
    opacity: 1,
    transition: {
      duration: pageTransitionSpeed / 1000,
      delay: 0.2,
      ease: 'linear',
      when: 'beforeChildren',
    },
  },
  exit: {
    opacity: 0,
    transition: {
      duration: pageTransitionSpeed / 1000,
      ease: 'linear',
      when: 'beforeChildren',
    },
  },
}

const Layout = ({
  site,
  page,
  canonicalUrl,
  productSchema,
  blogPostSchema,
  hasSmoothScroll,
  children,
}: LayoutProps) => {
  const { setHeaderHeight } = useContext(SiteContext)
  const scrollContainerRef = useRef(null)
  const topObserverRef = useRef(null)

  const { route } = useRouter()
  const { height: windowHeight } = useWindowSize()

  // Save relevant header sizes
  const [headerSizeStyle, setHeaderSizeStyle] =
    useState<CSSPropertiesWithHeader>({})

  // Set window height lock (with Safari/iOS hack)
  const [lockHeight, setLockHeight] = useState(false)
  const hasChin = isMobileSafari()

  useEffect(() => {
    if (isBrowser && !lockHeight) {
      document.body.style.setProperty('--vh', `${windowHeight * 0.01}px`)
      setLockHeight(hasChin)
    }
  }, [hasChin, lockHeight, windowHeight])

  const hasTransparentHeader =
    'hasTransparentHeader' in page && !!page.hasTransparentHeader

  const handleHeaderResize = useCallback(
    ({ height, bottom }: HeaderSizeValues) => {
      setHeaderHeight(height)

      setHeaderSizeStyle({
        '--headerHeight': `${height}px`,
        '--headerBottom': `${bottom}px`,
      })
    },
    [setHeaderHeight]
  )

  let pageContent = (
    <div style={headerSizeStyle}>
      <Header
        menuDesktopLeft={site.header?.menuDesktopLeft}
        menuDesktopRight={site.header?.menuDesktopRight}
        menuMobilePrimary={site.header?.menuMobilePrimary}
        menuMobileSecondary={site.header?.menuMobileSecondary}
        promo={site.header?.promo}
        logo={site.identity?.logo}
        invertedLogo={site.identity?.invertedLogo}
        isTransparent={hasTransparentHeader}
        onResize={handleHeaderResize}
        topObserverRef={topObserverRef}
      />
      <div
        className="min-h-screen flex flex-col justify-between overflow-x-hidden"
        data-scroll-container={hasSmoothScroll}
        ref={scrollContainerRef}
      >
        <main
          id="content"
          className="mb-10"
          data-scroll-content={hasSmoothScroll}
        >
          <span
            ref={topObserverRef}
            className="relative top-[var(--headerHeight)]"
          />

          {children}
        </main>
        <Footer
          blocks={site.footer?.blocks}
          copyright={site.footer?.copyright}
          paymentMethods={site.footer?.paymentMethods}
        />
      </div>
      <VideoModal />
    </div>
  )

  if (site.settings?.pageAnimation) {
    pageContent = (
      <motion.div
        initial="initial"
        animate="enter"
        exit="exit"
        variants={variants}
      >
        {pageContent}
      </motion.div>
    )
  }

  if (hasSmoothScroll) {
    pageContent = (
      <LocomotiveScrollProvider
        options={{ smooth: true }}
        watch={[route]}
        containerRef={scrollContainerRef}
      >
        {pageContent}
      </LocomotiveScrollProvider>
    )
  }

  return (
    <>
      <HeadSeo
        site={site}
        page={page}
        canonicalUrl={canonicalUrl}
        productSchema={productSchema}
        blogPostSchema={blogPostSchema}
      />

      {site.cookieConsent?.enabled && (
        <CookieBar
          enabled={site.cookieConsent.enabled}
          message={site.cookieConsent.message}
          link={site.cookieConsent.link}
        />
      )}

      {pageContent}
    </>
  )
}

export default Layout
