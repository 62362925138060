import {
  SanityProductGalleryPhoto,
  SanityProductVariantFragment,
} from '@data/sanity/queries/types/product'
import { getProductGalleryPhotos } from '@lib/product'

import Gallery from '@blocks/gallery'

interface ProductGalleryProps {
  photosets: SanityProductGalleryPhoto[]
  activeVariant: SanityProductVariantFragment
  previewVariant?: SanityProductVariantFragment
  hasArrows?: boolean
  hasDots?: boolean
  hasDrag?: boolean
  hasCounter?: boolean
  preloadFirstPhoto?: boolean
}

const ProductGallery = ({
  photosets,
  activeVariant,
  previewVariant,
  hasArrows = false,
  hasDots = false,
  hasDrag = false,
  hasCounter = false,
  preloadFirstPhoto = false,
}: ProductGalleryProps) => {
  // Get preview variant photos or active variant photos
  const previewVariantPhotos = previewVariant
    ? getProductGalleryPhotos(photosets, previewVariant)
    : []
  const photos =
    previewVariantPhotos.length > 0
      ? previewVariantPhotos
      : getProductGalleryPhotos(photosets, activeVariant)

  return (
    <Gallery
      photos={photos}
      hasArrows={hasArrows}
      hasDots={hasDots}
      hasDrag={hasDrag}
      hasCounter={hasCounter}
      preloadFirstPhoto={preloadFirstPhoto}
    />
  )
}

export default ProductGallery
