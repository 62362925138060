import { useState } from 'react'
import { motion, Variants } from 'framer-motion'
import cx from 'classnames'

import { SanityLinkFragment } from '@data/sanity/queries/types/link'

import DropdownItem from './dropdown-item'

const dropdownAnimation: Variants = {
  open: {
    opacity: 1,
    height: 'auto',
  },
  closed: {
    opacity: 0,
    height: 0,
  },
}

interface DropdownProps {
  id: string
  title: string
  items: SanityLinkFragment[]
  onClick?: () => void
  buttonClassName?: string
  listItemClassName?: string
  listItemLinkClassName?: string
}

const Dropdown = ({
  id,
  title,
  items,
  onClick,
  buttonClassName,
  listItemClassName,
  listItemLinkClassName,
}: DropdownProps) => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <div className="relative z-10">
      <button
        onClick={() => setIsOpen(!isOpen)}
        aria-expanded={isOpen}
        aria-controls={`dropdown-${id}`}
        className={buttonClassName}
      >
        {title}
      </button>

      <motion.div
        id={`dropdown-${id}`}
        className={cx(
          'relative h-0 overflow-y-hidden',
          'sm:absolute sm:top-full sm:whitespace-nowrap sm:overflow-y-visible sm:opacity-0 sm:invisible sm:pointer-events-none sm:transition-all sm:h-auto'
        )}
        initial={isOpen ? 'open' : 'closed'}
        animate={isOpen ? 'open' : 'closed'}
        variants={dropdownAnimation}
        transition={{ duration: 0.5, ease: [0.19, 1.0, 0.22, 1.0] }}
      >
        <ul className="block relative">
          {items.map((item) => (
            <DropdownItem
              key={item._key}
              item={item}
              isOpen={isOpen}
              onClick={onClick}
              className={listItemClassName}
              linkClassName={listItemLinkClassName}
            />
          ))}
        </ul>
      </motion.div>
    </div>
  )
}

export default Dropdown
