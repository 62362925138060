import { useContext } from 'react'
import cx from 'classnames'

import { SiteContext } from '@lib/site'

interface MegaNavigationBackdropProps {
  isOpen: boolean
}

const MegaNavigationBackdrop = ({ isOpen }: MegaNavigationBackdropProps) => {
  const { toggleMegaNavigation } = useContext(SiteContext)

  return (
    <div
      className={cx(
        'hidden lg:block',
        'fixed inset-0 z-0 bg-dark bg-opacity-40 pointer-events-none',
        'transition-opacity duration-150 ease-linear',
        {
          'opacity-0': !isOpen,
          'pointer-events-auto opacity-100 backdrop-blur-[6px]': isOpen,
        }
      )}
      onClick={() => toggleMegaNavigation(false)}
      onBeforeInput={() => toggleMegaNavigation(false)}
      role="presentation"
    />
  )
}

export default MegaNavigationBackdrop
