import BlockContent from '@sanity/block-content-to-react'
import cx from 'classnames'

import { SanityFooterFragment } from '@data/sanity/queries/types/site'
import { serializers } from '@lib/serializers'

import Newsletter from '@blocks/newsletter'
import Menu from '@blocks/navigation/menu'
import Icon, { IconName } from '@components/icon'
import Photo from '@components/photo'

type FooterProps = SanityFooterFragment

const Footer = ({ blocks, copyright, paymentMethods }: FooterProps) => (
  <footer
    className="relative overflow-hidden bg-pageText bg-opacity-[4%] text-pageText pt-6 pb-10"
    role="contentinfo"
  >
    <div className="grid sm:grid-cols-2 lg:grid-cols-4">
      {blocks?.map((block, index) => (
        <div
          key={index}
          className={cx('flex flex-col p-8 text-left', {
            'lg:order-first': 'newsletter' in block,
            'lg:order-last': 'social' in block,
          })}
        >
          {block.title.length > 0 && (
            <h2 className="text-3xl font-normal leading-tight mb-4">
              {block.title}
            </h2>
          )}

          {'description' in block && block.description && (
            <BlockContent
              renderContainerOnSingleChild
              className="rc mb-5"
              blocks={block.description}
              serializers={serializers}
            />
          )}

          {'menu' in block && block.menu.items.length > 0 && (
            <Menu items={block.menu.items} isFooterMenu />
          )}

          {'newsletter' in block && (
            <Newsletter
              id={block.newsletter._key}
              // TODO: Move to newsletter block settings.
              service="klaviyo"
              klaviyoListID={block.newsletter.klaviyoListID}
              submit={block.newsletter.submit}
              successMsg={block.newsletter.successMsg}
              errorMsg={block.newsletter.errorMsg}
              terms={block.newsletter.terms}
            />
          )}

          {'social' in block && !!block.social && (
            <div className="flex flex-wrap mb-4 mt-2">
              {block.social.map((link) => (
                <a
                  id={`footer-social-link-${link.icon.toLowerCase()}`}
                  className="mr-3 last:mr-0"
                  key={link._key}
                  href={link.url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Icon
                    id="footer"
                    name={link.icon as IconName}
                    className="block pointer-events-none h-[24px]"
                  />
                </a>
              ))}
            </div>
          )}
        </div>
      ))}
    </div>

    {(copyright || paymentMethods) && (
      <div
        className={cx('flex items-center px-8', {
          'justify-end': !copyright && paymentMethods,
          'justify-center sm:justify-end': copyright && !paymentMethods,
          'flex-col md:flex-row-reverse justify-between':
            copyright && paymentMethods,
        })}
      >
        {paymentMethods && (
          <div className="flex flex-row items-center mb-6 md:mb-0">
            {paymentMethods.map((paymentMethod) => (
              <div
                key={paymentMethod.id}
                className="relative block mr-2 last-of-type:mr-0"
              >
                <Photo
                  image={paymentMethod}
                  forceWidth={24 * paymentMethod.dimensions.aspectRatio}
                  forceHeight={24}
                  hasPlaceholder={false}
                />
              </div>
            ))}
          </div>
        )}

        {copyright && <p className="text-center text-sm">{copyright}</p>}
      </div>
    )}
  </footer>
)

export default Footer
