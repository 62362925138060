import BlockContent from '@sanity/block-content-to-react'
import cx from 'classnames'
import { motion, AnimatePresence } from 'framer-motion'
import {
  type BaseSyntheticEvent,
  type FormEvent,
  useContext,
  useState,
} from 'react'
import { useForm } from 'react-hook-form'

import { type SanityNewsletterBlock } from '@data/sanity/queries/types/blocks'
import { fadeAnimation } from '@lib/animate'
import { LanguageContext } from '@lib/language'
import { serializers } from '@lib/serializers'
import { addEmailToNewsletterList } from '@lib/services'
import { StringsContext } from '@lib/strings'

import Alert from '@components/alert'
import Button from '@components/buttons/button'
import Checkbox from '@components/checkbox'
import InputField from '@components/input-field'

type NewsletterProps = Omit<SanityNewsletterBlock, '_key' | '_type'> & {
  id: string
  service: 'klaviyo'
  className?: string
  isInvertedColors?: boolean
}

interface NewsletterFormValues {
  fullname: string
  email: string
  acceptTerms?: boolean
}

const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i

const Newsletter = ({
  id,
  service,
  klaviyoListID,
  terms,
  submit,
  successMsg,
  errorMsg,
  className,
  isInvertedColors,
}: NewsletterProps) => {
  const newsletterTargetId = service === 'klaviyo' ? klaviyoListID : null

  const strings = useContext(StringsContext)
  const { locale } = useContext(LanguageContext)

  const [isSubmitting, setIsSubmitting] = useState(false)
  const [isSuccess, setIsSuccess] = useState(false)
  const [isError, setIsError] = useState(false)

  const { handleSubmit, register, watch, reset } =
    useForm<NewsletterFormValues>()
  const hasAcceptedTerms = !!terms ? watch('acceptTerms') : true
  const email = watch('email')
  const fullnameRegister = register('fullname')
  const emailRegister = register('email', {
    required: strings.emailMissing,
    pattern: {
      value: emailRegex,
      message: strings.emailInvalid,
    },
  })
  const acceptTermsRegister = register('acceptTerms')

  const resetForm = (event: FormEvent) => {
    event.preventDefault()

    reset()
    setIsError(false)
    setIsSuccess(false)
    setIsSubmitting(false)
  }

  const onSubmit = async (
    newsletterFormValues: NewsletterFormValues,
    event?: BaseSyntheticEvent
  ) => {
    event?.preventDefault()

    setIsError(false)
    setIsSuccess(false)

    // Show an error if there is no newsletter target
    if (!newsletterTargetId) {
      setIsError(true)
      return
    }

    // Stop if accepting of terms is required
    if (!hasAcceptedTerms && !!terms) {
      return
    }

    setIsSubmitting(true)

    try {
      await addEmailToNewsletterList(
        locale,
        service,
        newsletterTargetId,
        !!hasAcceptedTerms,
        newsletterFormValues.email,
        newsletterFormValues.fullname
      )
      setIsSuccess(true)
    } catch (error) {
      console.log(error)
      setIsError(true)
    }

    setIsSubmitting(false)
  }

  const isDisabled =
    isSubmitting || !hasAcceptedTerms || !emailRegex.test(email)

  if (!service) {
    return null
  }

  return (
    <form className={cx(className)} onSubmit={handleSubmit(onSubmit)}>
      <AnimatePresence mode="wait">
        {!isError && !isSuccess && (
          <motion.div
            initial="hide"
            animate="show"
            exit="hide"
            variants={fadeAnimation}
          >
            <input
              type="text"
              autoComplete="off"
              aria-hidden="true"
              onChange={fullnameRegister.onChange}
              onBlur={fullnameRegister.onBlur}
              ref={fullnameRegister.ref}
              name={fullnameRegister.name}
              className="hidden"
            />

            <InputField
              id={`newsletter-${id}-email`}
              type="email"
              formRegister={emailRegister}
              placeholder={strings.emailAddress}
              borderBottom
              isInvertedColors={isInvertedColors}
              className="mb-4"
            >
              <Button
                type="submit"
                small
                disabled={isDisabled}
                className="font-semibold ml-3"
              >
                {submit}
              </Button>
            </InputField>

            {terms && (
              <Checkbox
                id={`newsletter-${id}-acceptTerms`}
                formRegister={acceptTermsRegister}
                isInvertedColors={isInvertedColors}
                className="mb-4"
              >
                <BlockContent
                  renderContainerOnSingleChild
                  blocks={terms}
                  serializers={serializers}
                  className="rc rc-checkbox"
                />
              </Checkbox>
            )}
          </motion.div>
        )}

        {isSuccess && (
          <motion.div
            key="success"
            initial="hide"
            animate="show"
            exit="hide"
            variants={fadeAnimation}
          >
            <Alert>
              <BlockContent
                renderContainerOnSingleChild
                blocks={successMsg}
                serializers={serializers}
                className="rc rc-alert"
              />
            </Alert>
          </motion.div>
        )}

        {isError && (
          <motion.div
            key="error"
            initial="hide"
            animate="show"
            exit="hide"
            variants={fadeAnimation}
          >
            <Alert buttonText={strings.buttonTryAgain} onClick={resetForm}>
              <BlockContent
                renderContainerOnSingleChild
                blocks={errorMsg}
                serializers={serializers}
                className="rc rc-alert"
              />
            </Alert>
          </motion.div>
        )}
      </AnimatePresence>
    </form>
  )
}

export default Newsletter
