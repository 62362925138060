import { useContext } from 'react'
import cx from 'classnames'

import {
  SanityProductOption,
  SanityProductOptionSetting,
  SanityProductVariantFragment,
} from '@data/sanity/queries/types/product'
import { hasObject } from '@lib/helpers'
import { StringsContext } from '@lib/strings'

import { getButtonStyles } from '@components/buttons/button'
import RadioGroup from '@components/radio-group'
import RadioItem from '@components/radio-item'
import Swatch from '@components/swatch'

interface ProductOptionProps {
  label?: string
  option: SanityProductOption
  optionSettings: SanityProductOptionSetting[]
  position: number
  variants: SanityProductVariantFragment[]
  activeVariant: SanityProductVariantFragment
  onChange: (variant: SanityProductVariantFragment) => void
  onPreview?: (variant?: SanityProductVariantFragment) => void
  strictMatch?: boolean
  hideLabels?: boolean
  inProductCard?: boolean
}

/**
 * Finds variant from given product options.
 */
const getVariantFromOptions = (
  variants: SanityProductVariantFragment[],
  activeVariant: SanityProductVariantFragment,
  option: SanityProductOption,
  value: string
) => {
  const newOptions = activeVariant.options.map((variantOption) =>
    variantOption.name === option.name
      ? { ...variantOption, value }
      : variantOption
  )

  // Find variant that matches all new options
  return variants.find(({ options }) =>
    options.every((variantOption) => hasObject(newOptions, variantOption))
  )
}

const ProductOption = ({
  label,
  option,
  optionSettings,
  position,
  variants,
  activeVariant,
  inProductCard,
  onChange,
  onPreview,
  strictMatch = true,
  hideLabels = false,
}: ProductOptionProps) => {
  const strings = useContext(StringsContext)

  const otherOptions = [
    ...activeVariant.options.slice(0, position),
    ...activeVariant.options.slice(position + 1),
  ]
  const selectedValue =
    activeVariant.options.find(
      (variantOption) => variantOption.name === option.name
    )?.value ?? ''

  return (
    <div
      className={cx('flex', {
        'mt-2 py-2 items-center': !inProductCard,
        'm-0 p-0 border-0': inProductCard,
      })}
    >
      {!hideLabels && (
        <div className="mr-12 text-xs font-semibold uppercase tracking-wider">
          {label || option.name}
        </div>
      )}

      <RadioGroup
        value={selectedValue}
        onChange={(value) => {
          const newVariant = getVariantFromOptions(
            variants,
            activeVariant,
            option,
            value
          )

          if (newVariant && newVariant.id !== activeVariant.id) {
            onChange(newVariant)
          }
        }}
        className={cx('flex flex-wrap justify-end -mt-2', {
          'mx-auto': inProductCard,
          'ml-auto': !inProductCard,
        })}
      >
        {option.values.map((value) => {
          const currentOption = [{ name: option.name, value }]
          const withActiveOptions = [...currentOption, ...otherOptions]
          const optionColor = optionSettings.find(({ forOption }) => {
            const optionParts = forOption?.split(':')

            return (
              optionParts?.[0] === option.name && optionParts?.[1] === value
            )
          })?.color
          const isActive = activeVariant.options.some(
            (variantOption) =>
              variantOption.position === option.position &&
              variantOption.value === value
          )
          const hasVariants = variants.find(({ options }) =>
            options.every((variantOption) =>
              hasObject(withActiveOptions, variantOption)
            )
          )
          const inStock = variants.find(({ inStock, options }) => {
            if (!inStock) {
              return false
            }

            if (strictMatch) {
              return options.every((variantOption) =>
                hasObject(withActiveOptions, variantOption)
              )
            }

            return options.some((variantOption) =>
              hasObject(currentOption, variantOption)
            )
          })

          const onMouseEnter = () => {
            const newVariant = getVariantFromOptions(
              variants,
              activeVariant,
              option,
              value
            )

            if (onPreview && newVariant) {
              onPreview(newVariant)
            }
          }
          const onMouseLeave = () => {
            if (onPreview) {
              onPreview()
            }
          }

          return (
            <RadioItem
              key={value}
              value={value}
              className={cx('mt-2 ml-2', {
                'p-0 bg-transparent': !!optionColor,
              })}
              onMouseEnter={onMouseEnter}
              onMouseLeave={onMouseLeave}
            >
              {optionColor && (
                <Swatch
                  label={strings.productColorOptionLabel
                    .replace(/{value}/gi, value)
                    .replace(/{name}/gi, option.name.toLowerCase())}
                  color={optionColor}
                  isActive={isActive}
                  isCrossed={!inStock && hasVariants && !isActive}
                />
              )}
              {!optionColor && (
                <div
                  className={cx(
                    getButtonStyles({
                      tertiary: true,
                      small: true,
                      active: isActive,
                    }),
                    { 'opacity-30': !inStock && hasVariants && !isActive }
                  )}
                >
                  {value}
                </div>
              )}
            </RadioItem>
          )
        })}
      </RadioGroup>
    </div>
  )
}

export default ProductOption
